<!--
 * @Description: 
 * @Author: Chp
 * @Date: 2021-05-26 18:02:25
 * @LastEditTime: 2021-05-27 14:34:03
 * @LastEditors: Chp
 * @Reference: 
-->
<template>
  <div class="notice-article">
    <SonTitle :title="detail.name"></SonTitle>
    <RichArticle :html="detail.content"></RichArticle>
  </div>
</template>

<script>
export default {
  data () {
    return {
      id: '',
      detail: {}
    }
  },
  created () {
    this.id = this.$route.query.id
    this.getDetail()
  },
  methods: {
    getDetail () {
      let params = {
        id: this.id
      }
      this.$api.getNoticeDetail(params).then((res) => {
        this.detail = res.data.data
      })
    }
  },
}
</script>

<style scoped lang='scss'>
</style>
